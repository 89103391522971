import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/users/authorize`, {email, password})
      .pipe(map(user => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }

  updatePassword(password: string) {
    return this.http.put<any>(`${environment.apiUrl}/users/update_password`, {password});
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.router.navigate(['/authentication/login']);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getMe() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(`${environment.apiUrl}/users/me/${currentUser._id}`);
  }
}
