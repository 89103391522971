<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile" style="background-color: white">
  <!-- User profile image -->
  <div style="padding: 31px 0">
    <!--<img src="assets/images/users/profile.png" alt="user"> -->
  </div>
  <!-- User profile text-->
  <!-- ============================================================== -->
  <!-- Profile - style you can find in header.scss -->
  <!-- ============================================================== -->
  <div class="profile-text">
    <!--<a *ngIf="currUser" [matMenuTriggerFor]="sdprofile" class=""> {{currUser.nameFirst}} {{currUser.nameLast}}-->
    <a *ngIf="currUser" class=""> {{currUser.nameFirst}} {{currUser.nameLast}}
<!--    <a *ngIf="currUser" class=""> {{currUser.companyName}}-->
      <!--<i class="ti-angle-down font-12 m-l-5"></i>-->
    </a>
  </div>
  <mat-menu #sdprofile="matMenu" class="mymegamenu">
    <!--<button mat-menu-item>-->
    <!--<mat-icon>settings</mat-icon> Settings-->
    <!--</button>-->
    <button mat-menu-item (click)="goToProfile()">
      <mat-icon>account_box</mat-icon> Profile
    </button>
    <!--<button mat-menu-item>-->
    <!--<mat-icon>notifications_off</mat-icon> Disable notifications-->
    <!--</button>-->
    <!--<button mat-menu-item>-->
    <!--<mat-icon>exit_to_app</mat-icon> Sign Out-->
    <!--</button>-->
  </mat-menu>
</div>

<mat-nav-list appAccordion>
  <!--<mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected" [routerLinkActiveOptions]="{exact:true}"  group="{{menuitem.state}}" (click)="closeSideBar(menuitem.name)">-->
  <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected" [routerLinkActiveOptions]="{exact:true}"  (click)="closeSideBar(menuitem.name)">
    <!--<a class="" appAccordionToggle [routerLink]="['/', menuitem.state, menuitem.subState ? menuitem.subState : '']" [queryParams]="menuitem.queryParams" *ngIf="checkAvailability(menuitem) && menuitem.type === 'link'">-->
    <!--<a class="" appAccordionToggle (click)="navigateMenuItem(menuitem)" [routerLink]="['/', menuitem.subState ? menuitem.state + '/' + menuitem.subState : menuitem.state]" [queryParams]="menuitem.queryParams" *ngIf="checkAvailability(menuitem) && menuitem.type === 'link'">-->
    <!--<a class="" appAccordionToggle (click)="navigateMenuItem(menuitem)" *ngIf="checkAvailability(menuitem) && menuitem.type === 'link'">-->
    <a class="" appAccordionToggle [routerLink]="'/' + menuitem.state + (menuitem.subState ? ('/' + menuitem.subState) : '')"

       *ngIf="checkAvailability(menuitem) && menuitem.type === 'link'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>

    <a class="" appAccordionToggle href="{{menuitem.state}}" *ngIf="checkAvailability(menuitem) && menuitem.type === 'extLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a class="" appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name}}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>

    <a class="" appAccordionToggle href="javascript:;" *ngIf="checkAvailability(menuitem) && menuitem.type === 'sub'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>
    <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
      <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="selected" (click)="closeSubSideBar()">
        <a [routerLink]="['/', menuitem.state, childitem.state ]" *ngIf="childitem.type === 'link'" class="relative"
           routerLinkActive="selected">{{ childitem.name}}</a>
      </mat-list-item>
      <mat-list-item *ngFor="let childitem of menuitem.children" (click)="closeSubSideBar()">
        <a class="" href="javascript: void(0);" *ngIf="childitem.type === 'subchild'" (click)="clickEvent()"
           [ngClass]="status ? 'selected' : ''">
          <span>{{ childitem.name }}</span>
          <span fxFlex></span>
          <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
          <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
            <a [routerLink]="['/', menuitem.state, childitem.state, child.state ]" routerLinkActive="selected">{{child.name}}</a>
          </mat-list-item>
        </mat-nav-list>
      </mat-list-item>

    </mat-nav-list>

    <div class="saperator text-muted" *ngIf="checkAvailability(menuitem) && menuitem.type === 'saperator'">
      <span>{{ menuitem.name }}</span>
    </div>
  </mat-list-item>
</mat-nav-list>
