<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
  [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark }">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar color="primary" class="topbar telative" style="background-color: rgb(0 26 51);">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" href="/">
        <!-- Logo icon -->
        <b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <!--          <img src="assets/images/cwrx/cwrx_effectiveness.png" alt="homepage" class="dark-logo" style="width: 148px;">-->
          <img src="assets/images/cwrx/cwrx-logo-white.svg" alt="homepage" class="dark-logo" style="width: 175px;">
          <!-- Light Logo icon -->
          <!--          <img src="assets/images/cwrx/cwrx_effectiveness.png" alt="homepage" class="light-logo" style="width: 148px;">-->
          <img src="assets/images/cwrx/cwrx-logo-white.svg" alt="homepage" class="light-logo" style="width: 175px;">
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <!--        <span fxShow="false" fxShow.gt-xs>-->
        <!--          &lt;!&ndash; dark Logo text &ndash;&gt;-->
        <!--          <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo">-->
        <!--          &lt;!&ndash; Light Logo text &ndash;&gt;-->
        <!--          <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage">-->
        <!--        </span>-->
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->

    <button mat-icon-button (click)="snav.toggle() && clickEvent()" value="sidebarclosed">
      <mat-icon>menu</mat-icon>
    </button>
    <!-- ============================================================== -->
    <!-- Search - style you can find in header.scss -->
    <!-- ============================================================== -->
    <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">
      <mat-icon>search</mat-icon>
    </button>
    <form class="app-search" [ngClass]="{'show-search': showSearch}">
      <input type="text" class="form-control" placeholder="Search &amp; enter">
      <a class="cl-srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-header></app-header>
    <!-- ============================================================== -->
    <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <!--    <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">-->
    <!--      <mat-icon>settings</mat-icon>-->
    <!--    </button>-->
  </mat-toolbar>
  <ng-template *ngFor="let notification of this.clientNotifications; let index = index">
    <mat-toolbar color="primary" class="topbar telative" style="background-color: rgb(0 26 51);" *ngIf="!notification.hidden">
      <div fxLayout="row wrap" fxLayoutAlign="start center" style="height: 100%;">
        <div class="notification-icon">
          <mat-icon inline>access_time</mat-icon>
        </div>
        <div class="notification-text" fxFlex>
          <!--              <div [innerHtml]="notification.message | safeHtml"></div>-->
          <div>{{notification.message}}</div>
        </div>
        <div class="notification-text">
          <button mat-button class="notification-link-button" (click)="goToLink(notification.link)">{{notification.buttonText ? notification.buttonText : 'Click Here'}}</button>
        </div>
      </div>
      <!--    <div  class="client-notification" fxFlex="100">-->
      <!--      <mat-card>-->
      <!--        -->
      <!--      </mat-card>-->
      <!--    </div>-->
    </mat-toolbar>
  </ng-template>

  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
      (close)="sidebarOpened = false" [perfectScrollbar]="config">

      <app-sidebar class="app-sidebar"></app-sidebar>

    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-nav-list>

          <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
          <mat-list-item>
            <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
          </mat-list-item>
          <!--<mat-list-item>
                    <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
                  </mat-list-item>    -->
          <mat-divider></mat-divider>
          <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal
              Green</mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

      <app-breadcrumb></app-breadcrumb>
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>

      </div>

    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>

