import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit, Output, EventEmitter
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import {AuthenticationService} from "../../../_services/authentication.service";
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  @Output() onClickMe: EventEmitter<any> = new EventEmitter();

  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  status: boolean = false;
  currUser = null;

  clickEvent() {
    this.status = !this.status;
  }

  isMobile() {
    return window.innerWidth < 960;
  }

  subclickEvent() {
    this.status = true;
  }
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public authService: AuthenticationService,
    private router: Router
  ) {
    this.currUser = this.authService.getCurrentUser();
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  checkAvailability(menuItem): boolean {
    let currUser = this.currUser;

    if (!this.currUser) {
      return false;
      // currUser = this.authService.getCurrentUser();
    }
    else {
      let role;

      if (currUser && currUser.isAdmin) {
        role = 'admin';
      }
      else if(currUser.userType) {
        role = currUser.userType;
      }
      else{
        role = 'user';
      }

      if (!menuItem.roles || menuItem.roles.length === 0) {
        return true;
      }
      else if (menuItem.roles.indexOf(role) > -1) {
        return true;
      }
      else {
        return false;
      }
    }


  }

  getRouterLink(menuItem){
    let link = ['/', menuItem.state];


    if(menuItem.subState){
      link.push(menuItem.subState);
    }

    return link;
    // this.router.navigate([link], { queryParams: menuItem.queryParams });
  }

  signOut(){
    this.authService.logout();
  }

  closeSideBar(name) {
    if (name !== 'Admin') {
      if (this.isMobile()) {
        this.onClickMe.emit();
      }
    }
  }

  closeSubSideBar() {
    if (this.isMobile()) {
      this.onClickMe.emit();
    }
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
